export default [{
  id: 'drawTime',
  name: 'Draw Time (seconds)',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 45,
  min: 1,
  max: 360
}, {
  id: 'numberOfRounds',
  name: 'Number of Rounds',
  type: Number,
  input: 'input',
  inputType: 'number',
  "default": 1,
  min: 1,
  max: 10
}, {
  id: 'wordTypes',
  name: 'Word Types',
  fullWidth: true,
  type: [String],
  input: 'multiple-select',
  "default": ['easy', 'medium', 'hard', 'objects'],
  options: [{
    key: 'easy',
    value: 'Easy'
  }, {
    key: 'medium',
    value: 'Medium'
  }, {
    key: 'hard',
    value: 'Hard'
  }, {
    key: 'objects',
    value: 'Objects'
  }, {
    key: 'people',
    value: 'People'
  }]
}];